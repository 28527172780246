import { Navigation } from "../components/navigation"

export const AGB = () => {
    return (
      <div>
        <Navigation />
        <div id='features'>
          <div className='container'>
            <div className='col-md-10 col-md-offset-1 section-title-short text-center'>
              <h2>AGB</h2>
            </div>
          </div>
        <div className='container'>
            <h3>Allgemeine Geschäftsbedingungen der Firma ZENAMO GBR</h3>
            <h4>1. Geltungsbereich</h4>
            <p>Die nachstehenden Allgemeinen Geschäftsbedingungen in der jeweils aktuellen Fassung – gelten für alle – auch zukünftigen – Verträge, 
              Lieferungen und sonstigen Leistungen mit Unternehmern (nachfolgend Kunde), soweit nicht die Auftragsbestätigung oder eine schriftliche 
              Vereinbarung mit dem Kunden etwas anderes bestimmt. Diese Geschäftsbedingungen gelten als Rahmenvereinbarung auch für alle weiteren 
              Rechtsgeschäfte zwischen den Vertragsparteien. Der Kunde erkennt diese Allgemeinen Geschäftsbedingungen durch Auftragserteilung oder 
              Annahme der gelieferten Produkte an. Anderslautenden Geschäftsbedingungen des Kunden widersprechen wir hiermit ausdrücklich. 
              Nebenabreden, Änderungen und Ergänzungen bedürfen in jedem Fall unserer schriftlichen Bestätigung.
            </p>
            <br></br>
            <h4>2. Angebot und Vertragsschluss</h4>
            <p>2.1 Unsere Angebote sind freibleibend.
              Verträge kommen daher erst zustande, wenn wir den Auftrag innerhalb von zwei Wochen ab Eingang des Auftrages schriftlich bestätigen 
              oder die Leistung erbringen. 
            </p>
            <p>
              2.2 Für den Inhalt des Vertrages ist die Auftragsbestätigung des Auftragnehmers oder – soweit eine solche 
              nicht vorliegt – dessen freibleibendes Angebot maßgebend. 
            </p>
            <p>
              2.3 Die zum Angebot gehörenden Unterlagen – Zeichnungen, Abbildungen, 
              usw. – enthalten, soweit nicht ausdrücklich als unmittelbar verbindlich bezeichnet, nur Näherungswerte, nicht jedoch verbindlich 
              zugesicherte Eigenschaften. 
            </p>
            <p>
              2.4 Alle Eigentums- und Urheberrechte an Angebot und sämtlichen dem Auftraggeber überlassenen 
              Unterlagen verbleiben bei dem Auftragnehmer. Angebot und Unterlagen dürfen ohne Genehmigung des Auftragnehmers weder an 
              Dritte weitergegeben, noch veröffentlicht oder vervielfältigt werden. 
            </p>
            <p>
              2.5 Ergänzungen, Abänderungen und Nebenabreden bei Vertragsschluss bedürfen zu ihrer Wirksamkeit der Schriftform. 
              Dies gilt auch für einen Verzicht auf dieses Schriftformerfordernis.
            </p>
            <br></br>
            <h4>3. Arbeitsplatzrichtlinien, Bauvorlagen, Ausführungsmaßnahmen, behördliche und sonstige Richtlinien und Genehmigungen sind vom 
              Auftraggeber selbst zu beschaffen und einzuhalten.
            </h4>
            <br></br>
            <h4>4. Liefertermine; Nachfristen; Teillieferungen</h4>
            <p>4.1 Liefertermine oder -fristen geltend als nur annähernd vereinbart, wenn sie nicht von uns ausdrücklich und schriftlich als 
              verbindlich bestätigt werden. 
            </p>
            <p>
              4.2 Bestätigte Lieferfristen beginnen mit dem Datum unserer schriftlichen Auftragsbestätigung, jedoch nicht vor Eingang einer vereinbarten 
              Anzahlung auf unserem Konto. Ist unsere Leistung auch von einer Mitwirkung des Kunden abhängig, so beginnt die Frist auch nicht, bevor der 
              Kunde seine Mitwirkungspflichten erfüllt hat. 
            </p>
            <p>
              4.3 Unsere Lieferpflicht ruht, solange sich der Kunde uns gegenüber mit Zahlungsverpflichtungen gleich aus welchem Vertragsverhältnis 
              in Verzug befindet. 
            </p>
            <p>
              4.4 Bei bestätigten Lieferterminen behalten wir uns eine Nachfrist von drei Wochen vor. Eine vom Kunden gesetzte Nachfrist muss schriftlich 
              gesetzt werden und ist in jedem Fall unangemessen, wenn sie weniger als drei Wochen beträgt. Je nach Art der geschuldeten Leistung kann eine 
              längere Nachfrist erforderlich sein. 
            </p>
            <p>
              4.5 Wird uns die Erbringung der Leistung aufgrund höherer Gewalt oder aus anderen ungewöhnlichen und unverschuldeten Umständen vorübergehend 
              unmöglich oder erschwert, so verlängert sich eine vereinbarte Leistungszeit um die Dauer dieses Leistungshindernisses. Gleiches gilt für eine 
              vom Besteller für die Leistungserbringung gesetzte Frist, insbesondere für Nachfristen gem. Punkt 4.4. Als Ereignisse höherer Gewalt gelten 
              insbesondere Krieg, kriegsähnliche Zustände, Mobilmachung, Ein- und Ausfuhrverbote und Blockaden. Andere außergewöhnliche und 
              unverschuldete Umstände sind insbesondere Transportbehinderungen, Betriebsstörungen, Verzögerungen in der Anlieferung von 
              Rohstoffen, Streiks, Aussperrungen und sonstige Arbeitskämpfe, auch wenn sie bei unserem Vorlieferanten eintreten. 
              Beginn und Ende derartiger Hindernisse teilen wir dem Kunden mit. 
            </p>
            <p>
              4.6 Vor Ablauf der gem. Nr. 4.5 verlängerten Leistungszeit ist der Käufer weder zum Rücktritt vom Vertrag noch zum Schadensersatz 
              berechtigt. Der Ausschluss des Rücktrittsrechts endet, wenn das Leistungshindernis mehr als sechs Wochen andauert; 
              in diesem Fall sind auch wir zum Rücktritt berechtigt. 
            </p>
            <p>
              4.7 Wir sind zu Teillieferungen berechtigt, soweit dem nicht berechtigte Interessen des Kunden entgegen stehen.
            </p><br></br>
            <h4>5. Preise</h4>
            <p>5.1 Alle Preise werden nach unserer – am Tage der schriftlichen Auftragsbestätigung – gültigen Preisliste berechnet und schließen 
              zusätzliche, vom Kunden gewünschte Sonderverpackungen oder Liefermodalitäten, nicht ein. Die Umsatzsteuer wird mit dem im Zeitpunkt 
              der Entstehung der Steuerschuld geltenden Satz zusätzlich berechnet.
            </p><br></br>
            <h4>6. Zahlungsbedingungen</h4>
            <ul>
              <li>a) Überweisung, Barkasse, PayPal innerhalb von 14 Tagen ab Rechnungsstellung. </li>
              <li>b) Bei 50% Anzahlung innerhalb einer Woche ab Auftragsbestätigung, wobei eine bestätigte Lieferfrist ab dem Zeitpunkt des Eingangs der 
              Anzahlung gilt.  </li>
              <li>c) Die Differenzzahlung erfolgt zu 100% innerhalb von 3 Tagen nach Lieferung. </li>
            </ul> <br></br>
            <h4>7. Gefahrenübergang</h4> 
            <p>Der Auftragnehmer trägt die Gefahr bis zur Übergabe der Produkte. Der Auftraggeber trägt die Gefahr vor Übergabe der Produkte, 
              wenn er die Übergabe verzögert oder unberechtigterweise verweigert.
            </p><br></br>
            <h4>8. Eigentumsvorbehalt</h4> 
            <p>8.1 Sämtliche von uns gelieferte Produkte werden unter Eigentumsvorbehalt geliefert und bleiben bis zur vollständigen Bezahlung unser 
              Eigentum. Veräußert der Kunde Vorbehaltsware weiter, so tritt er bereits jetzt an uns seine künftigen Forderungen aus der 
              Weiterveräußerung gegen seine Kunden mit allen Nebenrechten – einschließlich etwaiger Saldoforderungen – sicherungshalber ab, 
              ohne dass es noch späterer besonderer Erklärungen bedarf. Wird die Vorbehaltsware zusammen mit anderen Gegenständen weiter veräußert, 
              ohne dass für die Vorbehaltsware ein Einzelpreis vereinbart wurde, so tritt der Besteller dem Lieferer mit Vorrang vor der übrigen 
              Forderung denjenigen Teil der Gesamtpreisforderung ab, der dem vom Lieferer in Rechnung gestellten Preis der Vorbehaltsware entspricht. 
            </p>
            <p>
              8.2 Bei Pfändungen, Beschlagnahmen oder sonstigen Verfügungen oder Eingriffen Dritter hat der Kunde uns unverzüglich zu benachrichtigen. 
            </p>
            <p>
              8.3 Bei schuldhaftem Verstoß des Kunden gegen wesentliche Vertragspflichten, insbesondere bei Zahlungsverzug, sind wir nach Mahnung zur 
              Rücknahme berechtigt. Der Kunde ist zur Herausgabe verpflichtet. In der Rücknahme bzw. der Geltendmachung des Eigentumsvorbehaltes oder 
              der Pfändung des Liefergegenstandes durch uns liegt kein Rücktritt vomVertrag, es sei denn, wir hätten dies ausdrücklich er-klärt. 
              Wir sind nach vorheriger Androhung berechtigt, die zurückgenommene Vorbehaltsware zu verwerten und uns unter Anrechnung auf die 
              offenen Ansprüche aus deren Erlös zu befriedigen. 
            </p>
            <p>
              8.4 Soweit Liefergegenstände wesentliche Bestandteile des Grundstücks oder des Gebäudes geworden sind, verpflichtet sich der Auftragnehmer 
              bei Nichteinhaltung der vereinbarten Zahlungstermine dem Auftragnehmer die Demontage der Gegenstände zu gestatten und ihm das Eigentum 
              an diesen Gegenständen zurück zu übertragen. Beeinträchtigt der Auftraggeber die vorgenannten Rechte des Auftragnehmers, so ist er 
              diesem zum Schadensersatz verpflichtet. Die Demontage – und sonstigen Kosten – gehen zu Lasten und auf Kosten des Auftraggebers. 
              Werden Liefergegenstände mit einem anderen Gegenstand fest verbunden, so überträgt der Auftragnehmer, falls hierdurch Forderungen 
              oder Miteigentum entstehen, seine Forderungen oder sein Miteigentumsrecht an dem neuen Gegenstand an den Auftragnehmer.
            </p><br></br>
            <h4>9. Gewährleistung</h4>
            <p>9.1 Wir gewährleisten für die Dauer von 24 Monaten für Neuwaren und 12 Monate für Gebrauchtwaren ab Übergabe an den Kunden unabhängig von 
              der Anzahl der Betriebsstunden, dass unsere Produkte und Leistungen nicht mit Mängeln behaftet sind. Allerdings kann nach dem 
              gegenwärtigen Stand der Technik die ständige fehlerfreie Verwendbarkeit von Hardware, Software und Firmware nicht zugesichert werden. 
            </p>
            <p>
              9.2 Offensichtliche Mängel muss der Kunde uns innerhalb einer Woche nach Erhalt der Ware schriftlich anzeigen. Sonstige Mängel hat 
              der Kunde unverzüglich, das heißt spätestens eine Woche nach ihrer Entdeckung, uns gegenüber schriftlich zu rügen. Werden Mängel 
              nicht rechtzeitig angezeigt bzw. gerügt, so gilt die Ware insoweit als genehmigt. 
            </p>
            <p>
              9.3 Ist die gelieferte Ware mangelhaft und gilt sie nicht nach Nr. 9.2 als genehmigt, so steht dem Kunden zunächst lediglich 
              ein Anspruch auf Nacherfüllung zu, welche durch Ersatzlieferung erfolgen kann. Ersetzte Waren oder Produktteile gehen in unser Eigentum über. 
              Als ausreichende Nachbesserung gilt auch die Anweisung zur Umgehung der Auswirkungen von Mängeln. 
            </p>
            <p>
              9.4 Schadensersatzansprüche stehen dem Kunden nur bei Vorliegen der gesetzlichen Voraussetzungen und nur unter den Einschränkungen der 
              nachfolgenden Nr. 10 zu. 
            </p>
            <p>
              9.5 Sämtliche Mängelrechte des Kunden sind ausgeschlossen, soweit an der von uns gelieferten Ware Reparaturen oder sonstige Arbeiten 
              durch Kunden selbst oder Dritte ausgeführt werden und nicht auszuschließen ist, dass der Mangel hierauf beruht. Ferner 
              leisten wir keine Gewähr für Schäden, die auf eine der folgenden Ursachen zurückzuführen sind: – betriebsbedingte Abnutzung 
              und normaler Verschleiß über die Garantiedauer von 36 Monaten hinaus – unsachgemäßer Gebrauch, Bedienungsfehler und 
              fahrlässiges Verhalten des Kunden; – Betrieb mit falscher Strom-Art oder -spannung sowie Anschluss an ungeeignete Stromquellen; – Brand, 
              Blitzschlag, Explosion oder netzbedingte Überspannungen; – Feuchtigkeit oder ungeeignete Temperaturen. 
              Die Gewährleistung entfällt ferner, wenn Seriennummer, Typenbezeichnung, Herstellerbezeichnung oder 
              ähnliche Kennzeichen entfernt oder unleserlich gemacht werden. 
            </p>
            <p>
              9.6 Die Verjährungsfrist für sämtliche Rechte des Kunden wegen eines Mangels beläuft sich auf 24 Monate für Neuwaren und 
              12 Monate für Gebrauchtwaren und beginnt mit Übergabe an den Kunden.
            </p><br></br>
            <h4>10. Haftung; Aufrechnung</h4>
            <p>10.1 Für vorsätzliche oder grob fahrlässige Pflichtverletzungen sowie für Schäden aus einer Verletzung des Lebens, 
              des Körpers oder der Gesundheit haften wir unbeschränkt. Im Übrigen haften wir nur, wenn die verletzte Vertragspflicht 
              für das Erreichen des Vertragszwecks von wesentlicher Bedeutung ist, und nur begrenzt bis zur Höhe des typischerweise vorhersehbaren 
              Schadens.
            </p>
            <p>
              10.2 Die Haftungsbeschränkung in Nr. 10.1 gilt entsprechend für andere als vertragliche Schadensersatzansprüche, insbesondere 
              Ansprüche aus unerlaubter Handlung, mit Ausnahme der Ansprüche aus dem Produkthaftungsgesetz, und zwar auch zugunsten unserer 
              Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen. 
            </p>
            <p>
              10.3 Eine Aufrechnung ist nur mit einer unbestrittenen oder rechtskräftig festgestellten Forderung zulässig.
            </p><br></br>
            <h4>11. Garantie</h4>
            <p>11.1 Im Garantiefall gelten die Bestimmungen des Herstellers der Waren, Garantie wird nur vom Hersteller angeboten. 
            </p>
            <p>
              11.2 Die Garantieabwicklung erfolgt durch den Käufer mit dem Hersteller direkt. 
            </p>
            <p>
              11.3 Garantieabwicklungen können auch über uns erledigt werden, jedoch fällt dafür eine Gebühr in Höhe von mindestens 50,00€ incl. MwSt. an.
            </p><br></br>
            <h4>12. Einhaltung von Rechtsvorschriften; Ausfuhrkontrollbestimmungen</h4>
            <p>
              12.1 Die von uns gelieferten Produkte und deren technisches Know-how sind nur zur Benutzung und zum Verbleib in von uns direkt 
              belieferten Ländern bestimmt. Der Kunde verpflichtet sich die jeweiligen nationalen Rechtsvorschriften und vor einem 
              beabsichtigten Export oder Reexport alle einschlägigen nationalen und internationalen Rechtsvorschriften zu beachten. 
            </p>
            <p>
              12.2 Der Kunde ist für die Einhaltung der Rechtsvorschriften und sog. Ausfuhrkontrollbestimmungen selbst und auch durch 
              seine Abnehmer verantwortlich und stellt uns insoweit von jeder Haftung frei.
            </p>
            <br></br>
            <h4>13. Schlussbestimmungen; Abtretung; Gerichtsstand; Anwendbares Recht</h4>
            <p>
              13.1 Sollten einzelne Bestimmungen der vorstehend Allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam sein oder werden, 
              bleibt das Vertragsverhältnis im Übrigen hiervon unberührt. Für diesen Fall verpflichten sich die Parteien schon jetzt, 
              eine Ersatzregelung zu vereinbaren, die dem wirtschaftlich Gewollten am nächsten kommt. 
            </p>
            <p>
              13.2 Der Kunde kann seine Rechte und Pflichten aus diesem Vertrag nur mit unserer schriftlichen Zustimmung an Dritte übertragen. 
            </p>
            <p>
              13.3 Die Auftragsabwicklung erfolgt innerhalb unseres Unternehmens mit Hilfe automatisierter Datenverarbeitung. 
              Der Kunde erteilt hiermit seine ausdrückliche Zustimmung zur Verarbeitung der uns im Rahmen vertraglicher Beziehungen 
              bekannt gewordenen und zur Auftragsabwicklung notwendigen Daten. 
            </p>
            <p>
              13.4 Erfüllungsort und Gerichtsstand für sämtliche Ansprüche aus dem Vertragsverhältnis ist Deggendorf. 
              Wir sind jedoch berechtigt, den Kunden an jedem anderen gesetzlichen Gerichtstand zu verklagen. 
            </p>
            <p>
              13.5 Auf das Vertragsverhältnis mit allen unseren Kunden ist ausschließlich deutsches Recht anwendbar, deutsche Gerichtsbarkeit 
              gilt als vereinbart. 
            </p>
            <p>
              13.6. Pläne, Skizzen oder sonstige technische Unterlagen bleiben ebenso wie Muster, Kataloge, Prospekte, Abbildungen und dergleichen 
              stets unser geistiges Eigentum; der Kunde erhält daran keine wie immer gearteten Werknutzungs- oder Verwertungsrechte. 
              Bei entsprechender Zuwiderhandlung behalten wir uns rechtliche Schritte ausdrücklich vor. 
            </p>
            <p>
              13.7 Änderungen oder Ergänzungen dieses Vertrages bedürfen der Schriftform. Dies gilt auch für eine Aufhebung oder 
              Abänderung dieses Schriftformerfordernisses.
            </p>
            <p>
              13.8 Nebenabreden zu diesem Vertrag wurden nicht getroffen, soweit sie nicht im Rahmen dieses Vertrages schriftlich niedergelegt sind.
            </p>
          </div>
        </div>
      </div>
    )
  }
  