import { useState } from 'react'
import emailjs from 'emailjs-com'
import { Link } from 'react-router-dom'
import axios from 'axios'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const ContactForm = ({formSuccess}) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    // axios.post('https://reqres.in/api/articles', {
    //   name: name,
    //   email: email,
    //   message: message
    // })
    //     .then((result) => {
    //       console.log(result.text)
    //       clearState()
    //     },
    //     (error) => {
    //       console.log(error.text)
    //     });
    emailjs
      .sendForm(
        'service_maf7t1u', 'template_5bv5dzc', e.target, "user_J5psHX2bca2igrRnxpwWn"
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          formSuccess();
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div className='col-md-8'>
        <div className='row'>
            <div className='section-title'>
            <h2>Kontaktieren Sie uns</h2>
            <p>
            Bitte füllen Sie das untenstehende Formular aus, um uns eine E-Mail zu senden. Wir werden uns so schnell wie möglich bei Ihnen melden.</p>
            </div>
            <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-md-6'>
                <div className='form-group'>
                    <input
                    type='text'
                    id='name'
                    name='name'
                    className='form-control'
                    placeholder='Name'
                    required
                    onChange={handleChange}
                    />
                    <p className='help-block text-danger'></p>
                </div>
                </div>
                <div className='col-md-6'>
                <div className='form-group'>
                    <input
                    type='email'
                    id='email'
                    name='email'
                    className='form-control'
                    placeholder='Email'
                    required
                    onChange={handleChange}
                    />
                    <p className='help-block text-danger'></p>
                </div>
                </div>
            </div>
            <div className='form-group'>
                <textarea
                name='message'
                id='message'
                className='form-control'
                rows='4'
                placeholder='Nachricht'
                required
                onChange={handleChange}
                ></textarea>
                <p className='help-block text-danger'></p>
            </div>
            <div id='success'></div>
            <button type='submit' className='btn btn-custom btn-lg'>
                Senden
            </button>
            </form>
        </div>
    </div>
  )
}
