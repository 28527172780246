import React from 'react';
import ServicesCard from '../ServicesCard';
import { ServicesContainer, ServicesWrapper } from './ServicesBoxesElements';

const ServicesBoxes = (props) =>  {

    return (
        <ServicesContainer id="servicesBoxes">
            <ServicesWrapper>
            {props.data ? props.data.map((d, i) => <ServicesCard key={i} data={d}/>): 'loading'}
            </ServicesWrapper>
        </ServicesContainer>
    );
}

export default ServicesBoxes;