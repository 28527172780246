import { useState } from 'react';
import { ContactForm } from './contactForm'
import { ContactSuccess } from './contactSuccess';

export const Contact = (props) => {
  const [isSubmitted, setSubmitted] = useState(false);

  function formSuccess() {
    setSubmitted(true);
  }

  function closeSuccess() {
    setSubmitted(false);
  }
  
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          {!isSubmitted ? <ContactForm formSuccess={formSuccess}/> : <ContactSuccess closeSuccess={closeSuccess} />}
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Kontakt</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Adresse
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefonnummer
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
