import { useState, useEffect } from 'react'
import { Gallery } from './components/gallery'
//import { Testimonials } from './components/testimonials'
import { Team } from './components/Team'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  HashRouter,
} from "react-router-dom";
import { Home } from './pages/Home'
import { Impressum } from './pages/Impressum'
import { Datenschutz } from './pages/Datenschutz'
import { AGB } from './pages/AGB'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <HashRouter>
      <Switch>
        {/* <Redirect from="/#about" to="/home" />
        <Redirect from="/#contact" to="/home" />
        <Redirect from="/#feature" to="/home" />
        <Route exact path="/#service" to="/home" />
        <Redirect exact from='/' to='/home' /> */}
        <Route path='/home'>
          <Home data={landingPageData}/>
        </Route>
        <Route path='/datenschutz'>
          <Datenschutz/>
        </Route>
        <Route path='/impressum'>
          <Impressum/>
        </Route>
        <Route path='/agb'>
          <AGB/>
        </Route>
        <Route path='*'>
          <Home data={landingPageData}/>
        </Route>
      </Switch>
    </HashRouter>
  )
}

export default App
