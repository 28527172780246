import styled from 'styled-components'

export const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -120px;
    padding-bottom: 20px;

    @media screen and (max-width:768px) {
        margin-top: -130px;
    }
`

export const ServicesWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 10px;

    @media screen and (max-width:1200px) {
        grid-template-columns: 1fr 1fr;
        padding: 0 100px;
    }

    @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
        padding: 0 40px;
    }
`