import React from 'react'
import { HashLink } from 'react-router-hash-link';
import logo from './../../logo/gray.png';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, 
    FooterLinkItems, FooterLinkTitle, FooterLink, FooterLinkExternal, SocialMedia, 
    SocialMediaWrap, SocialLogo, WebsiteRights, SocialIconLink, SocialIcons, FooterLinkSmooth } from './FooterElements'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Schnellzugriff</FooterLinkTitle>
                                {/* <FooterLinkSmooth smooth to="#features">Leistungen</FooterLinkSmooth> */}
                                <FooterLinkSmooth smooth to="#services">Software</FooterLinkSmooth>
                                <FooterLinkSmooth smooth to="#about">Über uns</FooterLinkSmooth>
                                <FooterLinkSmooth smooth to="#team">Team</FooterLinkSmooth>
                                <FooterLinkSmooth smooth to="#contact">Kontakt</FooterLinkSmooth>
                        </FooterLinkItems>
                        {/* <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <FooterLinkExternal href='https://www.instagram.com/zenamo_software/'>Instagram</FooterLinkExternal>
                        </FooterLinkItems> */}
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Terms of Service</FooterLinkTitle>
                                <FooterLink to="/impressum">Impressum</FooterLink>
                                <FooterLink to="/datenschutz">Datenschutz</FooterLink>
                                <FooterLink to="/agb">AGB</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo src={logo} alt="Logo" />
                        <WebsiteRights>Zenamo @ {new Date().getFullYear() + " "} All rights reserved.</WebsiteRights>
                        <SocialIcons>
                            {/* <SocialIconLink href="/" target="_blank" aria-label="Twitter"><i className='fab fa-twitter'></i>
                            </SocialIconLink> */}
                            <SocialIconLink href="https://www.instagram.com/zenamo_software/" target="_blank" aria-label="Instagram"><i className='fab fa-instagram'></i>
                            </SocialIconLink>
                            <SocialIconLink href="https://www.linkedin.com/company/zenamo/" target="_blank" aria-label="LinkedIn"><i className='fab fa-linkedin'></i>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer