import lottie from 'lottie-web'
import { useEffect, useRef } from 'react';
import animations from '../../animations/animations';
import { Animation } from './ContactElements';

export const ContactSuccess = ({closeSuccess}) => {

    const container = useRef(null)

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animations['mail']
        })
    })
  
  return (
    <div className='col-md-8'>
        <div className='row'>
            <div className='section-title'>
                <h2>Anfrage versendet!</h2>
                <p>Wir werden uns so schnell wie möglich bei Ihnen melden.</p>
            </div>
            <Animation ref={container} />
            <button onClick={() => closeSuccess()} className='btn btn-custom btn-lg'>
                Ok
            </button>
        </div>
    </div>
  )
}
