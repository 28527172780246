import { useEffect } from "react"
import { About } from "../components/about"
import { Contact } from "../components/Contact/contact"
import { Features } from "../components/features"
import Footer from "../components/Footer"
import { Header } from "../components/header"
import { Navigation } from "../components/navigation"
import { Services } from "../components/services"
import { Team } from "../components/Team"
import ServicesBoxes from "../components/ServicesBoxes"

export const Home = ({data}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    return function cleanup() {
  }
  });

    return (
      <div>
        <Navigation withNavigation={true}/>
        <Header data={data.Header} />
        <ServicesBoxes data={data.Features}/>
        {/* <Features data={data.Features} /> */}
        <Services data={data.Services} />
        <About data={data.About} />
        {/* <Gallery /> */}
        {/* <Testimonials data={landingPageData.Testimonials} /> */}
        <Team data={data.Team} />
        <Contact data={data.Contact} />
        <Footer />
      </div>
    )
  }
  