import React, {useEffect, useRef} from 'react';
import { ServicesContainer, ServicesDesc, ServicesAnimation, ServicesTitle } from './ServicesCardElements';
import lottie from 'lottie-web'
import animations from '../../animations/animations';

function ServicesCard (props) {

    const container = useRef(null)

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animations[props.data.animation]
        })
    }, [props.data.animation])

    return (
        <ServicesContainer>
            <ServicesAnimation ref={container}/>
            <ServicesTitle>{props.data.title}</ServicesTitle>
            <ServicesDesc>{props.data.text}</ServicesDesc>
        </ServicesContainer>    
    );
}

export default ServicesCard;