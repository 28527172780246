import { Navigation } from "../components/navigation"

export const Impressum = () => {
    return (
      <div>
        <Navigation />
        <div id='features'>
          <div className='container'>
            <div className='col-md-10 col-md-offset-1 section-title-short text-center'>
              <h2>Impressum</h2>
            </div>
          </div>
        <div className='container'>
          <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
          <p>ZENAMO GbR<br />
          Michelsdorf 23<br />
          93413 Cham</p>

          <p><strong>Vertreten durch:</strong><br />
          Sandro Sembeni<br />
          Anja Stricker<br />
          Christoph Gmach</p>

          <h3>Kontakt</h3>
          <p>Telefon: +49 16090107118<br />
          E-Mail: info@zenamo-software.de</p>

          <h3>EU-Streitschlichtung</h3>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

          <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
          </div>
        </div>
      </div>
    )
  }
  