import { Link, NavLink } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
import React, { useState, useEffect } from "react"

export const Navigation = ({withNavigation}) => {
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(true)

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    if (withNavigation) {
      changeBackground()
      // adding the event when scroll change background
      window.addEventListener("scroll", changeBackground)
    }
    return () => {
      // This is its cleanup.
    };
  })

  return (
    <nav id='menu' className={(navbar ? "navbar-solid" : "navbar-transparent") + " navbar navbar-default navbar-fixed-top"}>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <a className={(navbar ? "navbar-brand" : "navbar-brand-white") + " page-scroll"} href={(withNavigation ? '#page-top' : '/#/home')}>
            Zenamo Software
          </a>
        </div>
        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          { withNavigation ?
          <ul className='nav navbar-nav navbar-right'>
            {/* <li>
              <HashLink smooth to="#features">Leistungen</HashLink>
            </li> */}
            <li>
              <a href='#services' className='page-scroll'>
              Software
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
              Über uns
              </a>
            </li>
            {/* <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li> */}
            {/* <li>
              <a href='#portfolio' className='page-scroll'>
                Gallery
              </a>
            </li> */}
            {/* <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li> */}
            <li>
              <a href='#team' className='page-scroll'>
              Team
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
              Kontakt
              </a>
            </li>
          </ul>
          : 
          <ul className='nav navbar-nav navbar-right'>
            <li><a href='/#/home'>Startseite</a></li>
            <li><a href='/#/datenschutz'>Datenschutz</a></li>
            <li><a href='/#/impressum'>Impressum</a></li>
            <li><a href='/#/agb'>AGB</a></li>
          </ul>
          }
        </div>
      </div>
    </nav>
  )
}
