import styled from 'styled-components'

export const Animation = styled.div`
	padding: 0 0 20px 0 ;
    height: 200px;

    @media screen and (max-width:1200px) {
        height: 140px;
    }

    @media screen and (max-width:768px) {
        height: 100px;
    }
`