import styled from 'styled-components'

export const ServicesContainer = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    height: 320px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    @media screen and (max-width:1200px) {
        height: 200px;
    }

    @media screen and (max-width:768px) {
        height: 180px;
    }

    &:hover{
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const ServicesAnimation = styled.div`
	padding: 0 0 20px 0 ;
    height: 200px;

    @media screen and (max-width:1200px) {
        height: 140px;
    }

    @media screen and (max-width:768px) {
        height: 100px;
    }
`

export const ServicesTitle = styled.h2`
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #000;
`

export const ServicesDesc = styled.p`
    font-size: 1.1rem;
    text-align: center;
`
